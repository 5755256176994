import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { concatMap, filter, map, Observable, of, tap, withLatestFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { QueryParamsRoutingService } from '@dougs/core/routing';
import { EcommerceOpenSynchronizedShopModalService } from '@dougs/ecommerce/ui';
import { SalesInvoice } from '@dougs/sales-invoice/dto';
import { SalesInvoiceStateService } from '@dougs/sales-invoice/shared';
import { OpenSalesInvoiceDrawerService } from '@dougs/sales-invoice/ui';
import { SubscriptionComponentService } from '@dougs/subscription/ui';
import { OpenTaskModalService, UserConversationSummariesService } from '@dougs/task/ui';
import { UserStateService } from '@dougs/user/shared';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';
import { OpenVendorInvoiceDrawerService } from '@dougs/vendor-invoice/ui';

@Injectable({ providedIn: 'root' })
export class ActionOnQueryParamsService {
  private readonly queryParamsOpened: {
    [key: string]: boolean;
  } = {};

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly openTaskModalService: OpenTaskModalService,
    private readonly queryParamsRoutingService: QueryParamsRoutingService,
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    private readonly openVendorInvoiceDrawerService: OpenVendorInvoiceDrawerService,
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
    private readonly subscriptionComponentService: SubscriptionComponentService,
    private readonly userConversationSummariesService: UserConversationSummariesService,
    private readonly ecommerceOpenSynchronizedShopModalService: EcommerceOpenSynchronizedShopModalService,
    private readonly salesInvoiceStateService: SalesInvoiceStateService,
    private readonly openSalesInvoiceDrawerService: OpenSalesInvoiceDrawerService,
  ) {}

  queryParamsAction$: Observable<void> = this.activatedRoute.queryParams.pipe(
    withLatestFrom(this.userStateService.loggedInUser$),
    filter(([_, loggedInUser]) => loggedInUser?.isAccountantOrAdmin),
    map(([params, _]) => params),
    filter((params) => params.taskId && !this.queryParamsOpened.taskId),
    tap(() => (this.queryParamsOpened.taskId = true)),
    map((params) => parseInt(params.taskId, 10)),
    concatMap((taskId: number) => (taskId ? this.openTaskModalService.openTaskModalFromRouter(taskId) : of(void 0))),
    tap(() => this.removeQueryParams('taskId')),
  );

  queryParamsVendorInvoice$: Observable<void> = this.activatedRoute.queryParams.pipe(
    filter((params) => params.vendorInvoiceId && !this.queryParamsOpened.vendorInvoiceId),
    tap(() => (this.queryParamsOpened.vendorInvoiceId = true)),
    map((params) => params.vendorInvoiceId),
    concatMap((vendorInvoiceId: string) => this.refreshVendorInvoiceIfNotInState(vendorInvoiceId)),
    concatMap((vendorInvoice: VendorInvoice | null) =>
      vendorInvoice ? this.openVendorInvoiceDrawerService.openVendorInvoiceDrawerFromRouter(vendorInvoice) : of(void 0),
    ),
    tap(() => this.removeQueryParams('vendorInvoiceId')),
  );

  openPlanModalOnQueryParams$: Observable<void> = this.activatedRoute.queryParams.pipe(
    filter((params) => params?.m === 'pack' && !this.queryParamsOpened.m),
    tap(() => {
      this.queryParamsOpened.m = true;
    }),
    concatMap(() => this.subscriptionComponentService.openPlansModal()),
    tap(() => {
      this.removeQueryParams('m');
    }),
  );

  openUserConversationModalOnQueryParams$: Observable<void> = this.activatedRoute.queryParams.pipe(
    filter((params) => params?.['user-conversation'] === 'opened' && !this.queryParamsOpened['user-conversation']),
    tap(() => (this.queryParamsOpened['user-conversation'] = true)),
    concatMap(() => this.userConversationSummariesService.openUserSummariesModal()),
    tap(() => this.removeQueryParams('user-conversation')),
  );

  openPlanModalCampaignOnQueryParams$: Observable<void> = this.activatedRoute.queryParams.pipe(
    filter((params) => params?.campaign === 'VATRemoteDeclaration' && !this.queryParamsOpened.campaign),
    tap(() => {
      this.queryParamsOpened.campaign = true;
    }),
    concatMap((params) => this.subscriptionComponentService.openPlansModal(params?.campaign)),
    tap(() => {
      this.removeQueryParams('campaign');
    }),
  );

  queryParamsSynchronizedShop$: Observable<void> = this.activatedRoute.queryParams.pipe(
    filter((queryParams) => !!queryParams.synchronizedShop && !this.queryParamsOpened.synchronizedShop),
    concatMap((queryParams) =>
      this.ecommerceOpenSynchronizedShopModalService.openSynchronizedShopModal(
        'https://' + queryParams.synchronizedShop,
      ),
    ),
    tap(() => this.removeQueryParams('synchronizedShop')),
  );

  queryParamsSalesInvoice$: Observable<void> = this.activatedRoute.queryParams.pipe(
    filter((params) => params.salesInvoiceId && !this.queryParamsOpened.vendorInvoiceId),
    tap(() => (this.queryParamsOpened.salesInvoiceId = true)),
    map((params) => params.salesInvoiceId),
    concatMap((salesInvoiceId: string) => this.refreshSalesInvoiceIfNotInState(salesInvoiceId)),
    concatMap((salesInvoice: SalesInvoice | null) =>
      salesInvoice ? this.openSalesInvoiceDrawerService.openSalesInvoiceDrawerFromRouter(salesInvoice) : of(void 0),
    ),
    tap(() => this.removeQueryParams('salesInvoiceId')),
  );

  private refreshVendorInvoiceIfNotInState(vendorInvoiceId: string): Promise<VendorInvoice | null> {
    const vendorInvoiceInState: VendorInvoice | undefined =
      this.vendorInvoiceStateService.findVendorInvoiceById(vendorInvoiceId);
    return vendorInvoiceInState
      ? Promise.resolve(vendorInvoiceInState)
      : this.vendorInvoiceStateService.refreshVendorInvoiceById(
          this.companyStateService.activeCompany?.id,
          vendorInvoiceId,
        );
  }

  refreshSalesInvoiceIfNotInState(salesInvoiceId: string): Promise<SalesInvoice | null> {
    const salesInvoiceInState: SalesInvoice | undefined =
      this.salesInvoiceStateService.findSalesInvoiceById(salesInvoiceId);
    return salesInvoiceInState
      ? Promise.resolve(salesInvoiceInState)
      : this.salesInvoiceStateService.refreshSalesInvoiceById(
          this.companyStateService.activeCompany?.id,
          salesInvoiceId,
        );
  }

  private removeQueryParams(queryParamsKey: string): void {
    void this.queryParamsRoutingService.updateQueryParams(queryParamsKey, null);
    this.queryParamsOpened[queryParamsKey] = false;
  }
}
